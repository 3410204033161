<template>
  <Modal
    id="deliveryInboxModal"
    size="is-medium"
    :isOpen.sync="isModalOpen"
    :header="data ? $t('schedule.editDeliveryToInbox') : $t('schedule.addDeliveryToInbox')"
    :loading="loading"
    :buttonLabel="btnSaveLabel"
    @cancel="resetForm"
    @save="onSubmit"
    :isDisabled="isDisabledModal || loading.addressPicker"
    ref="modal"
  >
    <Tags :loading="loading.addresses || loading.participant" :value="topTags" />
    <form ref="form" @submit.prevent="onSubmit">
      <div class="columns">
        <DatePicker
          class="column is-4"
          position="is-bottom-right"
          :label="$t('schedule.search.date')"
          v-model="formData.date"
          icon-right="calendar-today"
          :min-date="minDate"
          responseFormat="MM/DD/Y"
          required
        />
        <div class="flex is-align-items-center mt-5 ml-4">
          <iSwitch
            v-model="formData.is_collect_event"
            :text="$t('global.collect')"
            :disabled="!!leaveAtDoor"
          />
        </div>
      </div>
      <p class="subTitle">{{ $t('schedule.pickupInfo') }}</p>
      <div class="columns">
        <CDPSelector
          class="column"
          v-model="formData.pickup_address"
          required
          setFirst
          model="full_address"
          :tabindex="-1"
        />
      </div>
      <div class="columns">
        <Field
          ref="order_number"
          class="column is-6"
          :label="$t('global.orderNumber')"
          :maxlength="15"
          v-model="formData.tracking_number"
          :key="`on-${keys.participantKey}`"
          focus
          required
        >
          <template #validation-message>
            <small class="order-number-text"> {{ $t('modal.orderNumber') }}</small>
          </template>
        </Field>
        <CopaymentField
          class="column is-6"
          v-model="formData.copayment_amount"
          :disabled="!!formData.is_collect_event"
        />
      </div>
      <p class="subTitle">{{ $t('participant.infoLabel') }}</p>
      <div class="columns">
        <Autocomplete
          class="column is-full"
          :key="`participant-${keys.participantKey}`"
          :label="$t('participant.participant')"
          :api="{
            url: 'participants/search',
            query: 'all',
            params: [{ id: 'is_active', value: 1 }]
          }"
          field="full_name"
          :dataPreProcessor="participantDataPreProcessor"
          @select="onSelectParticipant"
          :selectOnBlur="false"
          :keepFirst="false"
          :localFiltering="false"
          @primitiveInput="onInput"
          :sortData="false"
        >
          <template #results="{ data }">
            <div>
              <strong>{{ data.participant_full_name }}</strong>
              <br />
              {{ data.defaultAddress && data.defaultAddress.full_address }}
            </div>
          </template>
        </Autocomplete>
      </div>

      <div class="columns">
        <Field
          class="column is-4"
          :label="$t('contact.last')"
          v-model="formData.client_last_name"
          required
        />
        <Field
          class="column is-4"
          :label="$t('contact.name')"
          v-model="formData.client_name"
          required
        />
        <Field
          class="column is-4"
          :label="$t('contact.middle')"
          v-model="formData.client_middle_name"
        />
      </div>

      <div class="columns">
        <AddressPicker
          class="column is-full"
          ref="addressPicker"
          v-model="address"
          :isLoading.sync="loading.addressPicker"
          :key="`address-${keys.itemsKey}`"
          addressMaxLength=""
          address1MaxLenght="30"
          useExtraInfoComment
        >
          <template #field>
            <Gender class="gender" v-model="formData.gender" required />
          </template>
        </AddressPicker>
      </div>
      <PhonesSelector
        :mobile.sync="formData.mobile_phone_number"
        :land.sync="formData.phone_number"
        :phones="formData.participant_phones"
      />
      <div class="columns" v-if="!formData.id">
        <Field
          class="column is-half"
          :label="$t('participant.clientCode')"
          v-model="formData.client_code"
          placeholder="AAA000000"
          :validation-message="$t('validations.clientCode')"
          :minlength="9"
          :maxlength="9"
          :pattern="clientCodeRegex"
        />
        <Field
          class="column is-half"
          :label="$t('contact.email')"
          v-model="formData.email"
          type="email"
          :validation-message="$t('validations.email')"
        />
      </div>
      <div class="columns">
        <FundingSourceSelector
          class="column is-full"
          required
          v-model="formData.funding_source_id"
          keepFirst
          tabindex="-1"
        />
      </div>
      <div class="columns">
        <Field
          class="column is-full"
          :label="$tc('global.comment', 2)"
          v-model="formData.comments"
          type="textarea"
          :rows="2"
          :placeholder="$t('global.placeholder.deliveryComment')"
        />
      </div>
      <div class="flex deliverySequence columns">
        <iSwitch
          class="is-one-third column"
          v-model="rush"
          :text="$t('global.rush')"
          :disabled="!!priority"
        />
        <iSwitch
          class="is-one-third column"
          v-model="leaveAtDoor"
          :text="$t('global.leave-at-door')"
          :disabled="!!formData.is_collect_event"
        />
        <iSwitch
          class="is-one-third column"
          v-model="priority"
          :text="$t('global.priority')"
          :disabled="!!rush"
        />
        <iSwitch
          class="is-one-third column"
          v-model="formData.refrigeration_needed"
          :text="$t('global.refrigerated')"
        />
        <iSwitch
          class="is-one-third column"
          v-model="formData.male_driver"
          :text="$t('global.maleDriver')"
          :disabled="!!formData.female_driver"
        />
        <iSwitch
          class="is-one-third column"
          v-model="formData.female_driver"
          :text="$t('global.femaleDriver')"
          :disabled="!!formData.male_driver"
        />
        <iSwitch class="column" v-model="hasRestriction" :text="$t('deliveries.restriction')" />
      </div>
      <DeliverRestrictions
        v-if="hasRestriction"
        :value="constraintDates"
        @change="onDeliveryTimesChange"
      />
    </form>
    <template #buttons>
      <Button
        v-if="!data"
        :label="$t('button.addAndCreate')"
        type="is-primary"
        :loading="loading.addAndCeateNew"
        :disabled="loading.addressPicker"
        @click="addAndCreateNew"
      />
    </template>
  </Modal>
</template>

<script>
import {
  AddressPicker,
  Autocomplete,
  Button,
  Field,
  Gender,
  Modal,
  iSwitch,
  PhonesSelector,
  DeliverRestrictions,
  DatePicker
} from '@/components';
import FundingSourceSelector from '@/components/Selectors/FundingSourceSelector.vue';
import { clientCodeRegex } from '@/utils/RegexValidations';
import { propOr } from 'ramda';
import { CopaymentField, Tags } from '../';
import { toast } from '@/utils/dialog';
import { exceptionMessage } from '../../utils/Helpers';
import { Normalize } from '@/utils/Text';
import { fullAddressHelper } from '@/utils/Address';
import CDPSelector from '../CDP/CDPSelector.vue';

export default {
  components: {
    AddressPicker,
    Autocomplete,
    Button,
    Field,
    FundingSourceSelector,
    Gender,
    iSwitch,
    Modal,
    PhonesSelector,
    DeliverRestrictions,
    DatePicker,
    Tags,
    CopaymentField,
    CDPSelector
  },
  created() {
    this.data
      ? (this.formData.date = this.data.delivery_date)
      : (this.formData.date = this.$moment().format('MM/DD/Y'));
  },
  beforeDestroy() {
    this.$emit('update:isOpen', false);
  },
  mounted() {
    if (this.data) this.setData(this.data);
  },
  data() {
    return {
      focus: true,
      address: {},
      formData: {
        participant_phones: null,
        mobile_phone_number: null,
        phone_number: null,
        copayment_amount: ''
      },
      keys: { participantKey: 0, itemsKey: 0 },
      isModalOpen: this.isOpen,
      isDisabledModal: false,
      deliveryTimes: null,
      hasRestriction: false,
      dateEnd: null,
      dateStart: null,
      loading: {
        save: false,
        get: false,
        addressPicker: false,
        addAndCeateNew: false
      },
      addAndCreate: false,
      leaveAtDoor: false,
      phonesUrl: null,
      priority: false,
      rush: false,
      clientCodeRegex
    };
  },
  computed: {
    iOffet() {
      return this.$store.getters.offset;
    },
    deliveryId() {
      return this.data?.id;
    },
    minDate() {
      return this.$moment().subtract(1, 'days').toDate();
    },
    btnSaveLabel() {
      return this.formData?.id ? this.$t('button.save') : this.$t('button.create');
    },
    constraintDates() {
      const { delivery_start_time, delivery_end_time } = this.formData;
      const { start_time: PST, end_time: PET } = this.permanentConstraint;
      return {
        type: 'delivery',
        active: !!delivery_start_time || !!delivery_end_time,
        start_time: delivery_start_time || PST || 0,
        end_time: delivery_end_time || PET || 0
      };
    },
    permanentConstraint() {
      const { permanent_start_time, permanent_end_time } = this.formData || {};
      const active = !!permanent_start_time || !!permanent_end_time;
      return {
        active,
        start_time: this.toLocal(permanent_start_time),
        end_time: this.toLocal(permanent_end_time)
      };
    },
    permanentComment() {
      const { permanent_comment } = this.formData || {};
      return permanent_comment || '';
    },
    topTags() {
      const tags = [];
      const { permanentConstraint, address, permanentComment } = this;
      if (permanentComment) tags.push({ text: this.$t('participant.pComment') });
      if (address?.gate_code)
        tags.push({ text: this.$t('global.gateCode'), tooltip: address?.gate_code });
      if (permanentConstraint.active) tags.push({ text: this.$t('participant.PDR') });
      return tags;
    }
  },
  methods: {
    toLocal(UTC) {
      if (!UTC) return;
      return this.$moment(UTC, 'HH:mm:ss')
        .add(this.iOffet * -1, 'hours')
        .format('HH:mm:ss');
    },
    filterDefaultPhone(phones = [], type) {
      return phones.filter((P) => P.number_type === type).find((P) => P.is_default);
    },
    participantDataPreProcessor(data) {
      const processedParticipants = data.map(({ _source: D }) => {
        return {
          ...D,
          addresss: D.participant_address.find((pa) => pa.is_default)?.full_address,
          defaultAddress: D.participant_address.find((pa) => pa.is_default) || {},
          defaultMobilePhone: this.filterDefaultPhone(D.participant_phone_number, 'mobile'),
          defaultLandPhone: this.filterDefaultPhone(D.participant_phone_number, 'land')
        };
      });
      let processedData = [];
      let item = {};
      processedParticipants.forEach((participant) => {
        participant.participant_address.forEach((address) => {
          item = { ...participant, defaultAddress: address };
          processedData = [...processedData, item];
        });
      });
      return processedData;
    },
    onInput(text) {
      if (!text) {
        this.onSelectParticipant();
      }
    },
    onSelectParticipant(P) {
      if (!P) return;
      const { defaultAddress: DA, defaultMobilePhone: DM, defaultLandPhone: DL } = P;
      const data = {
        client_address1: DA?.address || '',
        client_address2: DA?.address_1 || '',
        client_city: DA?.city || '',
        client_code: P?.participant_client_code || '',
        client_last_name: P?.participant_last_name || '',
        client_middle_name: P?.participant_middle_name || '',
        client_name: P?.participant_first_name || '',
        client_state: DA?.state,
        client_zip: DA?.zipcode || '',
        email: P?.participant_email || '',
        funding_source_id: P?.participant_funding_source_id || '',
        gender: P?.participant_gender,
        gate_code: DA.gate_code,
        id: P.id || '',
        latitude: DA?.latitude || '',
        longitude: DA?.longitude || '',
        mobile_phone_number: DM?.phone_number || '',
        participant_phones: P?.participant_phone_number || null,
        permanent_start_time: P?.participant_delivery_start_time || null,
        permanent_end_time: P?.participant_delivery_end_time || null,
        permanent_comment: P?.participant_permanent_comment || null,
        phone_number: DL?.phone_number || ''
      };
      this.setData(data);
      this.data ? (this.formData.date = this.data.delivery_date) : null;
      // this.keys.itemsKey += 1;
    },
    setData(data) {
      this.formData = { ...this.formData, ...data };

      switch (data.gender) {
        case 'F':
        case 'Female':
          this.formData.gender = 'Female';
          break;
        case 'M':
        case 'Male':
          this.formData.gender = 'Male';
          break;
        case 'U':
        case 'Unspecified':
          this.formData.gender = 'Unspecified';
          break;
      }

      this.address = {
        address: this.formData.client_address1,
        address_1: this.formData.client_address2,
        gate_code: this.formData.gate_code,
        city: this.formData.client_city,
        state: this.formData.client_state,
        zipcode: this.formData.client_zip,
        gateCode: this.formData.gateCode,
        latitude: this.formData.latitude,
        longitude: this.formData.longitude
      };

      this.rush = this.formData.rush === 1;
      this.priority = this.formData.priority === 1;
      this.leaveAtDoor = this.formData.leave_at_door === 1;

      this.updateComment(data.permanent_comment);

      this.hasRestriction = this.constraintDates.active || this.permanentConstraint.active;
    },
    prepareData() {
      let delivery_start_time = null;
      let delivery_end_time = null;

      let { address, formData: form, rush, priority, leaveAtDoor } = this;

      if (this.deliveryTimes) {
        delivery_end_time = this.deliveryTimes.delivery_end_time;
        delivery_start_time = this.deliveryTimes.delivery_start_time;
      }
      const is_collect_event = form.is_collect_event ? 1 : 0;
      const copayment_amount = form.copayment_amount || 0;

      return {
        client_address1: address.address,
        client_address2: address.address_1,
        client_city: address.city,
        client_code: form.client_code,
        client_last_name: form.client_last_name,
        client_middle_name: form.client_middle_name,
        client_name: form.client_name,
        client_state: address.state,
        client_zip: address.zipcode,
        comments: form.comments || '',
        delivery_date: form.date,
        delivery_end_time: this.hasRestriction ? delivery_end_time : null,
        delivery_start_time: this.hasRestriction ? delivery_start_time : null,
        email: form.email,
        funding_source_id: form.funding_source_id,
        gender: form.gender[0],
        gate_code: address.gate_code || null,
        latitude: address.latitude,
        longitude: address.longitude,
        mobile_phone_number: form.mobile_phone_number,
        phone_number: form.phone_number,
        formatted_address: fullAddressHelper(address),
        pickup_address: form.pickup_address,
        rush: rush ? 1 : 0,
        priority: priority ? 1 : 0,
        leave_at_door: leaveAtDoor ? 1 : 0,
        male_driver: form.male_driver,
        female_driver: form.female_driver,
        is_collect_event,
        copayment_amount: is_collect_event ? 0 : copayment_amount,
        refrigeration_needed: form.refrigeration_needed || 0,
        tracking_number: form.tracking_number
      };
    },
    setLoading(status) {
      if (status) {
        if (this.addAndCreate) {
          this.isDisabledModal = status;
          this.loading.addAndCeateNew = status;
        } else {
          this.loading.save = status;
        }
      } else {
        this.isDisabledModal = status;
        this.loading.addAndCeateNew = status;
        this.loading.save = status;
      }
    },
    async onSubmit() {
      if (this.validate()) {
        this.setLoading(true);
        try {
          const pData = this.prepareData();
          const method = this.data ? 'put' : 'post';
          const url = `/deliveries_inbox${this.data ? `/${this.data.id}` : ''}`;
          this.Api.ErrorProcess = false;
          const { data } = await this.Api[method](url, pData);
          if (data) {
            this.$emit('save');
            toast('success', this.$t('messages.saved'), 5000);
            this.resetForm();
          }
        } catch (error) {
          exceptionMessage({
            error,
            messageError: Normalize(this.$t('global.duplicatedOrderNumber'), { upper: true }),
            callBack: this.restoreErrorOrderNumber
          });
        }
        this.setLoading(false);
      }
    },
    restoreErrorOrderNumber() {
      this.$refs.order_number.isErrorOrderNumber = true;
    },
    resetForm() {
      this.address = {};
      this.hasRestriction = false;
      this.rush = false;
      this.priority = false;
      this.leaveAtDoor = false;
      if (this.addAndCreate) {
        this.addAndCreate = false;
        const { date, pickup_address, funding_source_id } = this.formData;
        this.formData = { date, pickup_address, funding_source_id };
        this.$refs.modal.scrollTop();
      } else {
        this.deliveryTimes = null;
        this.formData = { date: this.$moment().format('MM/DD/Y') };
        this.isModalOpen = false;
      }
      this.keys.itemsKey += 1;
      this.keys.participantKey += 1;
    },
    validate() {
      const addressValidator = this.$refs.addressPicker.validate();
      let dateValidation = false;
      let htmlValidator = false;
      let addressFieldValidator = false;
      if (String(this.$refs.addressPicker.value.address).length <= 60) addressFieldValidator = true;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }

      if (this.hasRestriction) {
        if (this.deliveryTimes) {
          dateValidation = true;
        } else {
          toast('warning', this.$t('messages.deadlines'), 5000);
        }
      } else {
        dateValidation = true;
      }
      if (!addressFieldValidator) toast('danger', this.$t('schedule.validateAddress'), 5000);
      return htmlValidator && addressValidator && dateValidation && addressFieldValidator;
    },
    onDeliveryTimesChange({ start_time, end_time }) {
      this.deliveryTimes = { delivery_start_time: start_time, delivery_end_time: end_time };
    },
    addAndCreateNew() {
      this.addAndCreate = true;
      this.onSubmit();
    },
    updateComment(newComment = '') {
      if (!newComment) return;
      const currentComments = propOr('', 'comments', this.formData);
      if (currentComments.indexOf(newComment) < 0) {
        this.formData.comments = `${newComment}\n${currentComments}`;
      }
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.onSelectParticipant();
      this.$emit('update:isOpen', value);
      if (!value) this.resetForm();
    },
    data(value) {
      if (value) this.setData(value);
    },
    address(value) {
      this.updateComment(value?.comment);
    }
  },
  props: {
    data: { type: Object, default: () => {} },
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#deliveryInboxModal
  .deliverySequence
    margin-top: 1rem
    flex-wrap: wrap
  .gender
    margin-right: 0
    width: calc(45% - 1.5rem - 35px)
  .order-number-text
    color: $red-800
    font-size: 12px
  ::v-deep
    .address-picker
      .ap-address
        width: 100%
      .ap-address, .ap-city, .ap-gender
        margin-right: 0
      .ap-city
        width: calc(66.666% - .75rem)
        margin-right: 0px
        .dropdown-menu
          width: auto
          .dropdown-item
            padding-right: 10px
      .ap-state
        width: 15%
      .ap-address1
        width: 33.333%
      .ap-gate-code
        width: 20%
      .ap-zipcode
        width: 19%
</style>
