import { filter, join, pick, pipe, values } from 'ramda';

export const fullAddressHelper = (address) => {
  try {
    return pipe(
      pick(['address', 'address_1', 'street', 'city', 'state', 'zip', 'zipcode']),
      values,
      filter((x) => !!x),
      join(', ')
    )(address);
  } catch (error) {
    console.log('::::::fullAddressHelper', error);
  }
};
