<template>
  <section>
    <Portal to="headerActions">
      <Link to="/schedule/imports/create-csv" isRouter class="mr-2" :permission="Permission.create">
        <Button type="is-primary" :label="$t('schedule.linkCreateSVG')" icon-left="note-text" />
      </Link>
    </Portal>
    <Card id="deliveries-inbox" />
  </section>
</template>

<script>
import { Button, Link } from '@/components';
import Card from '@/components/DeliveriesInbox/Card.vue';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Card,
    Link
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  computed: {
    breadcumbs() {
      return ['menu.inb'];
    },
    Permission() {
      return Permissions.DeliveryInboxProcessCSV;
    }
  }
};
</script>
<style lang="sass" scoped>
#deliveries-inbox
  margin: 0
</style>
