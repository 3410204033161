<template>
  <div id="schedule-deliveries-inbox-table">
    <AddEditModal v-if="showModal && data" :isOpen.sync="showModal" :data="data" @save="getData" />
    <Table
      class="deliveries-table"
      :params="aParams"
      :loading.sync="oLoading.table"
      :perPage="20"
      infiniteScroll
      :show-detail-icon="false"
      :apiUrl="searchApiUrl"
      :dataPreProcessor="deliveriesDataPreProcessor"
      ref="table"
      :checkable="ShowCheckBox"
      :checked-rows.sync="checkedRows"
    >
      <b-table-column :label="$t('global.orderNumber')" v-slot="props" :td-attrs="tdAttrs">
        <div class="flex f-wrap">
          <DeliveryIcons
            :status="{
              rush: props.row.rush,
              priority: props.row.priority,
              refrigerated: props.row.refrigeration_needed,
              leaveAtDoor: props.row.leave_at_door,
              collect: props.row.is_collect_event,
              male_driver: props.row.male_driver,
              female_driver: props.row.female_driver
            }"
          />

          <span> {{ props.row.tracking_number || '-' }} </span>
        </div>
        <CopaymentTag :value="props.row.copayment_amount" />
      </b-table-column>
      <b-table-column :label="$t('global.date')" v-slot="props" :td-attrs="tdAttrs">
        <DateTime class="f-jc" :date="props.row.delivery_date" format="MM/DD/Y" />
        <ConstraintDatesViewer
          isTag
          :startTime="props.row.delivery_start_time"
          :endTime="props.row.delivery_end_time"
        />
      </b-table-column>
      <b-table-column :label="$t('participant.clientCode')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.client_code || '-' }}
      </b-table-column>
      <b-table-column :label="$t('participant.name')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.full_name || '-' }}
      </b-table-column>
      <b-table-column :label="$t('participant.address')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.full_address || '-' }}
      </b-table-column>
      <b-table-column
        :label="$t('contact.phone') + ' #'"
        width="150"
        v-slot="props"
        :td-attrs="tdAttrs"
      >
        <Phone-viewer :phone="props.row.mobile_phone_number || props.row.phone_number" />
      </b-table-column>
      <b-table-column v-slot="props" :td-attrs="tdAttrs" width="80">
        <div class="flex">
          <Link
            class="btn-edit"
            type="is-primary"
            :label="$t('button.edit')"
            @click="onEdit(props.row)"
            icon="pencil"
            :loading="oLoading.rowId == props.row.id"
            :disabled="oLoading.table"
            :permission="Permission.update"
          />
          <Link
            class="btn-edit ml-1"
            type="is-primary"
            :label="$t('button.delete')"
            @click="onDelete(props.row.id)"
            icon="delete"
            :loading="oLoading.rowId == props.row.id"
            :disabled="oLoading.table"
            :permission="Permission.delete"
          />
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import {
  Link,
  Table,
  PhoneViewer,
  DateTime,
  ConstraintDatesViewer,
  DeliveryIcons
} from '@/components';
import AddEditModal from './AddEditModal.vue';
import { confirm } from '@/utils/dialog';
import { Permissions } from '@/utils/Secure';
import CopaymentTag from '../CopaymentTag.vue';

export default {
  components: {
    AddEditModal,
    Link,
    Table,
    PhoneViewer,
    DateTime,
    ConstraintDatesViewer,
    DeliveryIcons,
    CopaymentTag
  },
  data() {
    return {
      oLoading: { table: this.loading, rowId: 0 },
      data: {},
      showModal: false,
      checkedRows: []
    };
  },
  computed: {
    bTable() {
      return this.$refs?.table?.bTable;
    },
    aParams() {
      const aParams = ['sort=priority'];
      Object.keys(this.params).map((key) => {
        let value = this.params[key];
        if (value && value != 'all') aParams.push(`${key}=${value}`);
      });
      return aParams;
    },
    ShowCheckBox() {
      let isAllowed = false;
      if (Permissions.DeliveryInbox.delete?.length) {
        isAllowed = Permissions.DeliveryInbox.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(Permissions.DeliveryInbox.delete);
    },
    Permission() {
      return Permissions.DeliveryInbox;
    }
  },
  methods: {
    searchApiUrl(urlParams) {
      const hasAllParam = !!urlParams?.find((urlParam) => urlParam.indexOf('all=') >= 0);
      return `deliveries_inbox${hasAllParam ? '/search' : ''}`;
    },
    deliveriesDataPreProcessor(deliveries) {
      if (deliveries[0]?._source) return deliveries.map((RD) => RD._source);
      return deliveries;
    },
    async getData() {
      await this.$refs.table.getData({ clear: true });
    },
    async onDelete(id) {
      try {
        confirm({
          title: this.$t('confirms.continue'),
          message: this.$t('confirms.deleteDeliveryInbox'),
          cancelText: this.$t('confirm.no'),
          confirmText: this.$t('confirm.yes'),
          onConfirm: async () => {
            await this.Api.delete(`/deliveries_inbox/${id}`);
            this.getData();
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    onEdit(data) {
      this.showModal = true;
      this.data = data;
    },
    onClick(value) {
      this[value.event]();
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    }
  },
  watch: {
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    },
    checkedRows(value) {
      this.$emit('selectedRows', value);
    }
  },
  props: {
    loading: { type: Boolean, default: false },
    params: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="sass" scoped>
#schedule-deliveries-inbox-table
  margin-top: 20px
  border-radius: $br-md
  .deliveries-table
    ::v-deep
      td:nth-child(2)
        padding-right: 0
      td:nth-child(3)
        padding-left: 0.5rem
  .table-container
    min-height: calc(100% - 120px)
  ::v-deep
    .priority-icon
      margin-left: -15px
      color: $green-700
    .is-selected
      cursor: default
      .btn-edit
          opacity: 1
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
      &:hover
        .btn-edit
          opacity: 1
    .table-wrapper
      height: calc(100vh - 250px)
  .btn-edit
    opacity: 0
.w-130
  width: 130px
.w-140
  width: 140px
.w-250
  width: 250px
</style>
